jQuery(document).on("turbolinks:load", function () {
  let involved_id = jQuery("[id^=select_involved_]");
  if (involved_id) {
    involved_id.on("click", function () {
      var matches = this.name.match(/\[(.*?)\]/);

      jQuery("#involved_id").val(matches[1]);

      var formAction =
        jQuery("#form-edit-involved").attr("action") +
        "/edit?involved_id=" +
        matches[1];

      jQuery("#form-edit-involved").attr("action", formAction);
      jQuery("#button-edit-involved").attr("href", formAction);
    });
  }
});

jQuery(document).on("turbolinks:load", function () {
  $('*[data-href]').on('click', function() {
      window.location = $(this).data("href");
  });
});
