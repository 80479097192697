jQuery(document).on("turbolinks:load", function () {
  jQuery(".cdd-btn-icon").on("click", function (f) {
    var new_params = {};
    if (this.id == "involved_correlation_btn") {
      new_params = {
        name: $("#involved_denunciation_name")[0].value,
        correlation_id: $("#involved_correlation_id")[0].value,
        involved_type: $("#selected_involved_type")[0].value,
        job_title: $("#involved_denunciation_job_title")[0].value,
        involved_id: $("#involved_id")[0].value,
      };
    } else if (this.id == "category-btn") {
      new_params = build_new_params();
      if (new_params["severity"]) {
        delete new_params.severity;
      }
    } else {
      new_params = build_new_params();
    }
    this.href += "&" + jQuery.param(new_params);
  });
});

jQuery(document).on("turbolinks:load", function () {
  jQuery(".cdd-btn-icon-select").on("click", function (f) {
    var new_url =
      this.href.split("?")[0] +
      "?&return_select=true&" +
      $("#field_select_option")[0].value +
      "=" +
      this.id;
    $(".cdd-select-params").each(function () {
      if (this.name !== $("#field_select_option")[0].value) {
        new_url += "&" + this.name + "=" + this.value;
      }
    });
    this.href = "";
    this.href = new_url;
  });
});

function build_new_params() {
  let params = {};
  let info = [
    document.querySelector("#denunciation_category_id"),
    document.querySelector("#denunciation_location_id"),
    document.querySelector("#denunciation_correlation_id"),
    document.querySelector("#origin_from"),
    document.querySelector("#severity"),
    document.querySelector("#action_type_id"),
    document.querySelector("#priority"),
  ];

  info
    .filter((x) => {
      return x !== null;
    })
    .forEach((elem) => {
      try {
        if (elem.getAttribute("data-initial") !== elem.value) {
          params[elem.getAttribute("data-name")] = elem.value;
        }
      } catch (error) {
        console.error(error);
      }
    });

  return params;
}
