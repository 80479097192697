jQuery(document).on("turbolinks:load", function () {
  jQuery(document).ready(() => {
    window.setInterval(function() {
      if(jQuery("#selected_involved_type").val() !== ""){
        jQuery("#involved-color-"+jQuery("#selected_involved_type").val()).click();
      }
     }, 1000);
    function activeButton(value, btn_active, id_check, btns_to_disable){
      jQuery("#" + btn_active).addClass("active");
      document.getElementById(id_check).checked = true;
      jQuery.each(btns_to_disable, function(i, val) {
        jQuery("#" + val).removeClass("active");
      });
      jQuery("#selected_involved_type").val(value);
    }

    jQuery("#involved-color-witness").on("click", () => {
      activeButton("witness", "involved-color-witness", "involved_denunciation_involved_type_witness", ['involved-color-denounced', 'involved-color-mentioned', 'involved-color-denunciator']);
    });

    jQuery("#involved-color-denounced").on("click", () => {
      activeButton("denounced", "involved-color-denounced", "involved_denunciation_involved_type_denounced", ['involved-color-witness', 'involved-color-mentioned', 'involved-color-denunciator']);
    });

    jQuery("#involved-color-mentioned").on("click", () => {
      activeButton("mentioned", "involved-color-mentioned", "involved_denunciation_involved_type_mentioned", ['involved-color-witness', 'involved-color-denounced', 'involved-color-denunciator']);
    });
    
    jQuery("#involved-color-denunciator").on("click", () => {
      activeButton("denunciator", "involved-color-denunciator", "involved_denunciation_involved_type_denunciator", ['involved-color-witness', 'involved-color-denounced', 'involved-color-mentioned']);
    });
  });
});
