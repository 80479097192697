const { each } = require("jquery");

document.addEventListener("turbolinks:load", () => {
  let no_btn = document.querySelector(".is_identified_false");
  if (no_btn) {
    no_btn.addEventListener("click", (event) => {
      document.querySelector(".user_informations").style.display = "none";
      event.target.classList.add("active");
      document.querySelector(".is_identified_true").classList.remove("active");
      document.querySelector("#denunciation_is_identified_1").checked = false;
      document.querySelector("#denunciation_is_identified_0").checked = true;
    });

    document
      .querySelector(".is_identified_true")
      .addEventListener("click", (event) => {
        document.querySelector(".user_informations").style.display = "block";
        event.target.classList.add("active");
        document
          .querySelector(".is_identified_false")
          .classList.remove("active");
        document.querySelector("#denunciation_is_identified_1").checked = true;
        document.querySelector("#denunciation_is_identified_0").checked = false;
      });
  }

  let checkbox = document.querySelector("#denunciation_check_phone_number");

  if (checkbox) {
    checkbox.addEventListener("click", (event) => {
      if (document.getElementById("denunciation_check_phone_number").checked) {
        document.querySelector("#denunciation_phone_number").required = true;
      } else {
        document.querySelector("#denunciation_phone_number").required = false;
      }
    });
  }

  let trixToolbar = document.querySelector("trix-toolbar");

  if (trixToolbar) {
    trixToolbar.setAttribute("aria-hidden", "true");
  }

  let locationSelectInput = document.querySelector("#denunciation_location_id");

  if (locationSelectInput) {
    locationSelectInput.addEventListener("change", () => {
      fetchChildren(locationSelectInput);
    });
  }
});

jQuery(document).on("turbolinks:load", function () {
  if (document.getElementById("denunciation_is_identified_0")) {
    if (document.getElementById("denunciation_is_identified_0").checked) {
      document.querySelector(".user_informations").style.display = "none";
    } else {
      document.querySelector(".user_informations").style.display = "block";
      var vclass = document.querySelector("#active_is_indentified");
      vclass.classList.add("active");
    }
  }
});

jQuery(document).on("turbolinks:load", function () {
  if (jQuery("#invalid_accept_term").text().trim() !== "") {
    jQuery(jQuery("#denunciation_validate_field")).insertAfter(
      "#link_accept_term"
    );
    jQuery(jQuery("#checkmark_accept_term")).insertAfter(
      "#denunciation_validate_field"
    );
    jQuery("#checkmark_accept_term").click();
  }
});

jQuery(document).on("turbolinks:load", function () {
  jQuery("#denunciation_check_phone_number").on("click", () => {
    disablePhoneDiv();
  });
  jQuery("#denunciation_is_identified_check").on("click", () => {
    disableIdenfiedDiv();
  });
  function disablePhoneDiv() {
    if (jQuery("#denunciation_check_phone_number").is(":checked")) {
      jQuery("#div_phone_number").css("display", "block");
    } else {
      jQuery("#div_phone_number").css("display", "none");
    }
  }
  function disableIdenfiedDiv() {
    if (jQuery("#denunciation_is_identified_check").is(":checked")) {
      jQuery("#user_informations").css("display", "block");
      jQuery("#div_phone_number").css("display", "block");
      jQuery("#denunciation_check_phone_number").prop("checked", true);
      jQuery("#div_father_phone_number").css("display", "block");
      jQuery("#denunciation_phone_number").prop("required", "required");
    } else {
      jQuery("#user_informations").css("display", "none");
      jQuery("#div_phone_number").css("display", "none");
      jQuery("#denunciation_check_phone_number").prop("checked", false);
      jQuery("#div_father_phone_number").css("display", "none");
      jQuery("#denunciation_phone_number").removeAttr("required");
    }
  }
  disablePhoneDiv();
  disableIdenfiedDiv();
});

function fetchChildren(parentElement, nestedLevel = 0) {
  if (nestedLevel == 0) {
    jQuery(".location-children").remove();
  } else {
    for (let i = nestedLevel + 1; i <= jQuery(".location-children").length; i++) {
      jQuery(`#denunciation_location_id_${i}`).remove();
    }
  }

  let locationSelectInput = document.querySelector("#denunciation_location_id");
  let notInformedText = locationSelectInput.options[0].text;

  jQuery.ajax({
    url: "location_children",
    data: {
      location_id: parentElement.value,
    },
    success: function (data) {
      if (data["children"].length == 0) {
        return;
      }
      nestedLevel += 1;
      const select = jQuery('<select/>', {
        class: 'form-control btn cdd-btn-icon location-children mt-2',
        name: `denunciation[location_id_${nestedLevel}]`,
        id: `denunciation_location_id_${nestedLevel}`
      });

      select.append(jQuery('<option/>', {
        value: '',
        text: notInformedText
      }));

      jQuery.each(data["children"], function (index, item) {
        select.append(jQuery('<option/>', {
          value: item[1],
          text: item[0]
        }));
      });

      jQuery(parentElement).after(select);
      select.on("change", () => {
        fetchChildren(select[0], nestedLevel);
      });
    },
    error: function (data) {
      console.error(data);
    },
    dataType: "json",
  });
}
