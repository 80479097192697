$(document).on("turbolinks:load", function () {

  if($("#report-container").get(0) === undefined) return

  var reportContainer = $("#report-container").get(0);

  powerbi.bootstrap(reportContainer, { type: "report" });

  var reportLoadConfig = {
    type: "report",
    tokenType: 1,
  };

  let pathParams = window.location.pathname.split("/");
  let tenantPrefix = pathParams[1];
  let tenantname = pathParams[2];
  let path = `/${tenantPrefix}/${tenantname}/get_embed_info`;

  $.ajax({
    type: "GET",
    url: path,
    dataType: "json",
    success: function (data) {
      let embedData = $.parseJSON(JSON.stringify(data));
      reportLoadConfig.accessToken = embedData.token;
      reportLoadConfig.embedUrl = embedData.embedUrl;
      var report = powerbi.embed(reportContainer, reportLoadConfig);

      report.on("loaded", function () {
        console.log("Report load successful");
        report.updateSettings({
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          panes: {
            filters: {
              visible: false,
            },
          },
        });
      });

      report.on("rendered", function () {
        console.log("Report render successful");
        report.refresh();
      });

      report.off("error");

      report.on("error", function (event) {
        var errorMsg = event.detail;

        console.error(errorMsg);
        return;
      });
    },
    error: function (err) {
      console.error(err);
    },
  });
});
