jQuery(document).on("turbolinks:load", function () {
  jQuery("#is_identified_yes").click(function () {
    cancelAnonymous();
  });

  jQuery("#is_identified_yes_modal").click(function () {
    cancelAnonymous();
  });

  jQuery("#is_identified_no_modal").click(function () {
    jQuery(".is-identified").addClass("not-identified");
    jQuery(".is-identified").removeClass("is-identified");

    jQuery("#is_identified_no").addClass("active");
    jQuery("#is_identified_yes").removeClass("active");
  });

  var input = document.querySelector(".intl-tel-input");

  if (input) {
    window.intlTelInput(input, {
      initialCountry: "BR",
      separateDialCode: true,
      preferredCountries: ["BR"],
    });

    input.value = input.value.replace(" ", "").replace("-", "");
  }

  if (document.querySelector("#denunciation_category_id")) {
    document
      .querySelector("#denunciation_category_id")
      .addEventListener("change", () => {
        getEditCategorySeverity(jQuery("#denunciation_category_id").val());
      });
  }
});

document.addEventListener("turbolinks:load", () => {
  let yes_btn = document.querySelector(".is_identified_no");
  if (yes_btn) {
    yes_btn.addEventListener("click", () => {
      document.querySelector(
        "#denunciation_is_identified_true"
      ).checked = false;
      document.querySelector(
        "#denunciation_is_identified_false"
      ).checked = true;
    });

    document
      .querySelector(".is_identified_yes")
      .addEventListener("click", () => {
        document.querySelector(
          "#denunciation_is_identified_true"
        ).checked = true;
        document.querySelector(
          "#denunciation_is_identified_false"
        ).checked = false;
      });
  }
});

function cancelAnonymous() {
  jQuery(".not-identified").addClass("is-identified");
  jQuery(".not-identified").removeClass("not-identified");

  jQuery("#is_identified_no").removeClass("active");
  jQuery("#is_identified_yes").addClass("active");
}

function getEditCategorySeverity(category_id) {
  jQuery.ajax({
    url: "category_severity",
    data: {
      category_id: category_id,
    },
    success: function (data) {
      jQuery("#denunciation_severity").val(data.severity);
    },
    error: function (data) {
      jQuery("#denunciation_severity").val("0");
    },
    dataType: "json",
  });
}
