jQuery(document).on("turbolinks:load", function () {
  if (document.querySelector("#category_id")) {
    document.querySelector("#category_id").addEventListener("change", () => {
      getSeverity(
        jQuery("#category_id").val(),
        jQuery("#organization_id").val()
      );
    });
  }
  if (document.querySelector("#origin_from")) {
    document.querySelector("#origin_from").addEventListener("change", () => {
      if (document.querySelector("#protocol_number")) {
        if (document.querySelector("#origin_from").value == 4) {
          document.querySelector("#protocol_number").disabled = false;
        } else {
          document.querySelector("#protocol_number").disabled = true;
        }
      }
    });
  }

  if (document.querySelector("#organization")) {
    document.querySelector("#organization").addEventListener("change", () => {
      document.querySelector("#search_form").submit();
    });
  }
});

function getSeverity(category_id, organization_id) {
  jQuery.ajax({
    url: "category_severity",
    data: {
      category_id: category_id,
      organization_id: organization_id,
    },
    success: function (data) {
      jQuery("#severity").val(data.severity);
    },
    error: function (data) {
      jQuery("#severity").val("low");
    },
    dataType: "json",
  });
}
