jQuery(document).on("turbolinks:load", () => {
  let loginForm = document.querySelector("#login-form");
  let appUrl = document.querySelector("#app_url");

  let domain = window.location.href;

  if ((domain.includes("app") || domain.includes("local")) && !!loginForm) {
    loginForm.submit();
  } else if (!!appUrl){
    window.location.href = appUrl.value;
  }
});
