jQuery(document).on("turbolinks:load", function () {
    const blobLinks = document.querySelectorAll('.add-blank');

    if(blobLinks.length > 0) {
        blobLinks.forEach((item) => {
            item.setAttribute('target', '_blank')
        });
    }

    const spanForms = document.querySelectorAll('.add-download-form');

    if(spanForms.length > 0) {
        spanForms.forEach((item) => {
            var form = document.createElement('form');
            form.setAttribute('id', 'download_file');
            form.setAttribute('method', 'post');

            var action = item.querySelector('.form_action');
            form.setAttribute('action', action.innerHTML);
            action.remove();

            var procotocolNumber = document.createElement('input');
            procotocolNumber.setAttribute('type', 'hidden');
            procotocolNumber.setAttribute('id', 'protocol_number');
            procotocolNumber.setAttribute('name', 'protocol_number');
            procotocolNumber.setAttribute('value', document.querySelector('#protocol_number').innerHTML);
            form.append(procotocolNumber);

            var inputBlobId = document.createElement('input');
            inputBlobId.setAttribute('type', 'hidden');
            inputBlobId.setAttribute('id', 'blob_id');
            inputBlobId.setAttribute('name', 'blob_id');
            var blob_id = item.querySelector('.blob_id')
            inputBlobId.setAttribute('value', blob_id.innerHTML);
            blob_id.remove();
            form.append(inputBlobId);
            
            var inputSubmit = document.createElement('input');
            inputSubmit.setAttribute('type', 'submit');
            var download_label = item.querySelector('.download_label')
            inputSubmit.setAttribute('value', download_label.innerHTML);
            download_label.remove();
            form.append(inputSubmit);
            item.append(form);
        })
    }
});
