jQuery(document).on("turbolinks:load", function () {
  let backgroundDiv = document.querySelector("#bg-pages");

  if (backgroundDiv) {
    let dataURI = backgroundDiv.getAttribute("data-bg-img");

    if (dataURI) {
      backgroundDiv.style.backgroundImage = `url(${dataURI.replace(
        /[\r\n]/gm,
        ""
      )})`;
    }
  }
});
