jQuery( document ).on('turbolinks:load', function() {
  if (document.querySelector('#denunciation_group')) {
    getUsersByGroup();
    document.querySelector('#denunciation_group').addEventListener('change', () => {
      getUsersByGroup();
    });
  }
});

function getUsersByGroup() {
  jQuery.ajax({
    url: "users_by_group",
    data: {
      group_id : $('#denunciation_group').val(),
      tenant_name: $('#tenant_name').val()
    },
    success: function(data) {
      var users = JSON.parse(data);
      var userList = '';
      document.querySelector('#user_list').innerHTML = '<div></div>'
      if(users!=undefined){
        users.forEach(element => {
          userList = userList.concat(`<div class="row category-link no-gutters" data-link="#"><div class="col-10 content">${element.name}</div></div>`)
        });
        document.querySelector('#user_list').innerHTML = userList;
      }
    },
    dataType: "script"
  });
}
