import { AttachmentUpload } from "@rails/actiontext/app/javascript/actiontext/attachment_upload";

const maxFileSize = 25 * 1024 * 1024;
const maxVideoFileSize = 50 * 1024 * 1024;
const acceptedTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "audio/wav",
  "audio/mpeg",
  "audio/webm",
  "video/mp4",
  "video/mpeg",
  "video/webm",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "application/pdf",
  "application/office-document",
];

window.addEventListener("trix-attachment-add", async (event) => {
  const { attachment, target } = event;
  let byteStream = await readFile(attachment.file);
  const uint = new Uint8Array(byteStream);
  let bytes = [];
  uint.forEach((byte) => {
    bytes.push(byte.toString(16));
  });
  const hex = bytes.join("").toUpperCase();
  let mimeType = getMimetype(hex);

  if (!acceptedTypes.includes(mimeType)) {
    event.preventDefault();
    alert("File type not supported.");
    attachment.remove();
  } else if (
    mimeType.includes("video") &&
    attachment.file.size > maxVideoFileSize
  ) {
    event.preventDefault();
    attachment.remove();
    alert("Video file size is too big. The size limit is 50 MB.");
  } else if (
    !mimeType.includes("video") &&
    attachment.file.size > maxFileSize
  ) {
    event.preventDefault();
    attachment.remove();
    alert("File size is too big. The size limit is 25 MB.");
  } else {
    if (attachment.file) {
      const upload = new AttachmentUpload(attachment, target);
      upload.start();
    }
  }
});

function readFile(file) {
  return new Promise((resolve, reject) => {
    const filereader = new FileReader();
    filereader.onloadend = (event) => {
      if (event.target.readyState === FileReader.DONE) {
        resolve(event.target.result);
      }
    };
    filereader.onerror = reject;
    const blob = file.slice(0, 12);
    filereader.readAsArrayBuffer(blob);
  });
}

function getMimetype(signature) {
  if (
    signature.startsWith("FFFB") ||
    signature.startsWith("FFF3") ||
    signature.startsWith("FFF2") ||
    signature.startsWith("494433")
  ) {
    return "audio/mpeg";
  } else if (signature.startsWith("4F676753")) {
    return "audio/webm";
  } else if (signature.startsWith("52494646")) {
    return getRIFFBasedMimeType(signature);
  } else if (
    signature.startsWith("FFD8FFDB") ||
    signature.startsWith("FFD8FFE0")
  ) {
    return "image/jpeg";
  } else if (signature.startsWith("89504E47")) {
    return "image/png";
  } else if (signature.startsWith("47494638")) {
    return "image/gif";
  } else if (
    signature.startsWith("000001BA") ||
    signature.startsWith("000001B3")
  ) {
    return "video/mpeg";
  } else if (signature.startsWith("1A45DFA3")) {
    return "video/webm";
  } else if (signature.startsWith("3026B275")) {
    return "video/x-ms-wmv";
  } else if (signature.startsWith("25504446")) {
    return "application/pdf";
  } else if (
    signature.startsWith("D0CF11E0A1B11AE1") ||
    signature.startsWith("504B34140608000")
  ) {
    return "application/office-document";
  } else {
    return checkOffsets(signature);
  }
}

function getRIFFBasedMimeType(signature) {
  if (signature.endsWith("57415645")) {
    return "audio/wav";
  } else if (signature.endsWith("41564920")) {
    return "video/x-msvideo";
  } else if (signature.endsWith("57454250")) {
    return "image/webp";
  } else {
    return "Unknown filetype";
  }
}

function checkOffsets(signature) {
  if (signature.substring(5, signature.length - 1).startsWith("66747970")) {
    return "video/mp4";
  } else {
    return "Unknown filetype";
  }
}
