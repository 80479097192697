jQuery(document).on("turbolinks:load", () => {
  let headings = document.querySelectorAll("h4.denunciation-heading");
  let menu = document.querySelector("#denunciation-menu");

  if (!menu) {
    return;
  }

  headings.forEach((item, i) => {
    let text = item.textContent;
    item.setAttribute("id", `h4-${i}`);
    let listItem = document.createElement("li");
    let anchorTag = document.createElement("a");
    menu.appendChild(listItem);
    anchorTag.text = text;
    anchorTag.setAttribute("href", `#${item.getAttribute("id")}`);
    listItem.appendChild(anchorTag);
  });

  prepareFixedMenu();

  let scrollButton = document.querySelector(".btn-scroll-top");

  if (scrollButton) {
    let topAttribute = Number.parseInt(getComputedStyle(scrollButton).top);
    window.addEventListener("scroll", () => {

      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        scrollButton.style.display = "flex";
      } else {
        scrollButton.style.display = "none";
      }

      let newTopAttribute = window.scrollY + topAttribute;
      scrollButton.style.top = `${newTopAttribute}px`;
    });

    scrollButton.addEventListener("click", () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  }
});


function prepareFixedMenu() {
  let denunciationMenu = document.querySelector(".denunciation-menu");
  let fixedMenuDiv = document.querySelector("nav.denunciation-menu div.position-fixed");
  
  if (!denunciationMenu || !fixedMenuDiv) {
    return;
  }

  fixedMenuDiv.style.width = getComputedStyle(denunciationMenu).width;
}
