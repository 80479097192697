jQuery(document).on("turbolinks:load", function () {
  jQuery("div[data-link]").click(function () {
    window.location = this.dataset.link;
  });
  if (document.querySelector("#defaultModal")) {
    jQuery("#defaultModal").modal("show");
  }
  jQuery(".first-button").on("click", function () {
    jQuery(".animated-icon1").toggleClass("open");
  });

  jQuery("#index_filter").click(() => {
    jQuery("#filters").toggleClass("d-none");
  });

  jQuery("#close-filters").click(() => {
    jQuery("#filters").toggleClass("d-none");
  });

  jQuery("#clean-filters").click(() => {
    cleanFilters();
  });

  let filterInputs = document.querySelectorAll(".filter");

  if (filterInputs) {
    filterInputs.forEach((input) => {
      input.addEventListener("change", () => {
        countFilters();
      });
    });
  }

  countFilters();
  prepareScrollButton();
});

function countFilters() {
  let count = 0;
  const filterInputs = document.querySelectorAll(".filter");
  if (filterInputs) {
    filterInputs.forEach((input) => {
      if (input.value !== "") {
        count++;
        input.parentElement.firstElementChild.classList.add("active-filter");
      } else {
        input.parentElement.firstElementChild.classList.remove("active-filter");
      }
    });
  }

  const filterCount = document.querySelector("#filter_count");
  const secondaryFilterCount = document.querySelector("#filter_secondary_count");

  if (filterCount && count > 0) {
    filterCount.innerHTML = count;
    filterCount.classList.remove("d-none");
  } else if (filterCount) {
    filterCount.classList.add("d-none");
  }

  if (secondaryFilterCount && count > 0) {
    secondaryFilterCount.innerHTML = count;
    secondaryFilterCount.classList.remove("d-none");
  } else if (secondaryFilterCount) {
    secondaryFilterCount.classList.add("d-none");
  }
}

function cleanFilters() {
  const filterInputs = document.querySelectorAll(".filter");
  if (filterInputs) {
    filterInputs.forEach((input) => {
      input.value = ""
    });

    countFilters();
  }
}

function prepareScrollButton() {
  let scrollButton = document.querySelector(".btn-scroll-top");

  if (scrollButton) {
    let topAttribute = Number.parseInt(getComputedStyle(scrollButton).top);
    let navDiv = scrollButton.parentElement.parentElement;
    navDiv.addEventListener("scroll", () => {
      if (navDiv.scrollTop > 100) {
        scrollButton.style.display = "flex";
      } else {
        scrollButton.style.display = "none";
      }

      let newTopAttribute = navDiv.scrollTop + topAttribute;
      scrollButton.style.top = `${newTopAttribute}px`;
    });

    scrollButton.addEventListener("click", () => {
      navDiv.scrollTop = 0;
    });
  }
}
