document.addEventListener("turbolinks:load", () => {
  let internal_btn = document.querySelector("#lb_internal");
  let ethic_btn = document.querySelector("#lb_ethic_committee");
  let internal_ethic_btn = document.querySelector("#lb_internal_ethic_committee");

  if (internal_btn) {
    internal_btn.addEventListener("click", (event) => {
      if (ethic_btn) {
        ethic_btn.classList.remove("active");
        document.querySelector("#more_information_info_type_ethic_committee").checked = false;
      }
      if (internal_ethic_btn !== null) {
        internal_ethic_btn.classList.remove("active");
        document.querySelector("#more_information_info_type_internal_ethic_committee").checked = false;
      }
      document.querySelector("#lb_denunciator").classList.remove("active");
      document.querySelector("#more_information_info_type_denunciator").checked = false;

      document.querySelector("#lb_internal").classList.add("active");
      document.querySelector("#more_information_info_type_internal").checked = true;
    });
  }

  if (ethic_btn) {
    ethic_btn.addEventListener("click", (event) => {
      if (internal_btn) {
        internal_btn.classList.remove("active");
        document.querySelector("#more_information_info_type_internal").checked = false;
      }
      if (internal_ethic_btn) {
        internal_ethic_btn.classList.remove("active");
        document.querySelector("#more_information_info_type_internal_ethic_committee").checked = false;
      }
      document.querySelector("#lb_denunciator").classList.remove("active");
      document.querySelector("#more_information_info_type_denunciator").checked = false;

      document.querySelector("#lb_ethic_committee").classList.add("active");
      document.querySelector("#more_information_info_type_ethic_committee").checked = true;
    });
  }

  if (internal_ethic_btn) {
    internal_ethic_btn.addEventListener("click", (event) => {
      if (internal_btn) {
        internal_btn.classList.remove("active");
        document.querySelector("#more_information_info_type_internal").checked = false;
      }
      if (ethic_btn !== null) {
        document.querySelector("#lb_ethic_committee").classList.remove("active");
        document.querySelector("#more_information_info_type_ethic_committee").checked = false;
      }
      document.querySelector("#lb_denunciator").classList.remove("active");
      document.querySelector("#more_information_info_type_denunciator").checked = false;

      document.querySelector("#lb_internal_ethic_committee").classList.add("active");
      document.querySelector("#more_information_info_type_internal_ethic_committee").checked = true;
    });
  }

  document.querySelector("#lb_denunciator").addEventListener("click", (event) => {
    if (internal_btn) {
      internal_btn.classList.remove("active");
      document.querySelector("#more_information_info_type_internal").checked = false;
    }
    if (ethic_btn) {
      ethic_btn.classList.remove("active");
      document.querySelector("#more_information_info_type_ethic_committee").checked = false;
    }
    if (internal_ethic_btn) {
      internal_ethic_btn.classList.remove("active");
      document.querySelector("#more_information_info_type_internal_ethic_committee").checked = false;
    }
    document.querySelector("#lb_denunciator").classList.add("active");
    document.querySelector("#more_information_info_type_denunciator").checked = true;
  });


  let showContentLink = document.querySelector("p.show-content");

  if (showContentLink) {
    document.querySelector("p.show-content + div.trix-content").classList.toggle("hide");

    showContentLink.addEventListener("click", () => {
      document.querySelector("p.show-content + div.trix-content").classList.toggle("hide");
      showContentLink.classList.toggle("mb-0");
      showContentLink.querySelector("span:last-child").classList.toggle("d-none");
      showContentLink.querySelector("span:first-child").classList.toggle("d-none");
    });
  }
});